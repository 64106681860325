<div class="flex flex-col place-content-center place-items-center w-full p-4 md:w-2/5 lg:w-1/4">
  <a class="relative block h-48 overflow-hidden rounded" [routerLink]="'/dila/detail/' + item.id" routerLinkActive="active">
    <img [alt]="item.name" loading="lazy" class="block h-full w-full object-cover object-center cursor-pointer"
         [src]='item.photo|photoSecured:"1":"/plain/rs:fill:250:250:1/g:no/":item.shared | async' width="250" height="250" />
  </a>
</div>
<div class="p-4 md:w-1/3 lg:w-1/3">
  <div class="flex flex-col h-full justify-between mt-4">
    <div class="flex flex-col">
      <div>
        <canvas-item-labels [itemId]="item.id" [prefixNumber]="item?.contract?.prefixNumber" [catalogNumber]="item?.catalogNumber" [auctionNumber]="item?.auctionterms" [shared]="item.shared"></canvas-item-labels>
      </div>
      <a [routerLink]="'/dila/detail/' + item.id" class="title-font text-lg font-medium text-gray-900 inline hover:text-blue-600" routerLinkActive="active">
        <h2 [innerHTML]="item.name"></h2>
      </a>
      @if (item.datace) {
        <span>({{item.datace}})</span>
      }
      <h3 class="title-font font-medium text-gray-900">
        <kodl-author [authors]="item?.authors" [showDating]="true"></kodl-author>
      </h3>
      <div class="mt-1">{{item.technique}}</div>
      <div class="">
        @if(item.height || item.width || item.depth) {
          {{item.height ? item.height : ''}}{{item.width  ? ' x ' + item.width : ''}}{{item.depth ? ' x ' + item.depth : ''}} cm
        }
      </div>

      @if(showStatus) {
        <div class="mt-2 text-sm text-gray">{{itemStates[item.status]}} ({{item.datetime| date:"dd.MM.YYYY"}})</div>
      }

    </div>
    <div class="flex flex-col md:flex-row pb-4 gap-4">
      <button (click)="onEdit()" class="leading-5 text-xs flex flex-row items-center px-3 py-1 hover:bg-gray-200 rounded-2xl gap-2 text-black">
        <mat-icon svgIcon="mat:edit"></mat-icon> Upravit
      </button>
      @if(showChangeContractBtn) {
        <button (click)="onTransfer()" class="leading-5 text-xs flex flex-row items-center px-3 py-1 hover:bg-gray-200 rounded-2xl gap-2 text-black" matTooltip="Přesunutí obrazu do jiné smlouvy">
          <mat-icon svgIcon="mat:keyboard_backspace"></mat-icon> Přesunout
        </button>
      }
      @if(showDeletetBtn) {
        <button (click)="onDelete()" color="warn" class="leading-5 text-xs flex flex-row items-center px-3 py-1 hover:bg-gray-200 rounded-2xl gap-2 text-orange-400" matTooltip="Dojde ke smazání obrazu se smlouvy">
          <mat-icon svgIcon="mat:cancel"></mat-icon> Smazat
        </button>
      }
    </div>
  </div>
</div>
<div class="p-4 md:w-1/5 lg:w-1/5 flex flex-col items-center justify-center bg-slate-100 text-center">

  @if (item.auctionStartingPrice !== 0 && item.auctionStartingPrice !== null) {
  <div>
      <h2 class="title-font text-lg font-medium text-gray-900">{{item.auctionStartingPrice | currency:'CZK':'symbol':'0.0-0':'cs'}}</h2>
      <p class="">Vyvoláno v aukci</p>
  </div>
  }

  @if (item.auctionSalePrice !== 0 && item.auctionSalePrice !== null) {
  <div>
    <h2 class="mt-5 title-font text-lg font-medium text-gray-900">{{item.auctionSalePrice | currency:'CZK':'symbol':'0.0-0':'cs'}}</h2>
    <p class="">Vydraženo</p>
  </div>

<!--  <div>-->
<!--    <h2 class="mt-5 title-font text-lg font-medium text-gray-900">{{((item.auctionSalePrice / 100) * (100 + item.auctionRewardPercent)) | currency:'CZK':'symbol':'0.0-0':'cs'}}</h2>-->
<!--    <p class="">Cena s přirážkou</p>-->
<!--  </div>-->

  }


  @if (item.commissionPrice !== 0 && item.commissionPrice !== null) {
  <div>
    <h2 class="mt-5 title-font text-lg font-medium text-gray-900">{{item.commissionPrice | currency:'CZK':'symbol':'0.0-0':'cs'}}</h2>
    <p class="">Komise</p>
  </div>
  }

</div>
<div class="flex flex-col p-4 md:w-1/5 lg:w-1/5">
  <div class="flex flex-col items-center place-content-center">
    <h3 class="title-font text-xs tracking-widest text-gray-500">Služby</h3>
    <mat-progress-spinner class="mt-2" color="primary" mode="determinate" [value]="jobsProgress"></mat-progress-spinner>
<!--    <p-chart type="doughnut" class="max-w-fit" [data]="data" [options]="options"></p-chart>-->
    @if(item.jobs) {
      <h4 class="title-font mb-1 text-xs tracking-widest text-gray-500 mt-2">Dokončeno {{jobsDone}} z {{this.jobsLength}}</h4>
    }
  </div>
  <div class="flex items-end place-content-center text-center mt-2">
    @if (!isSubPanelOpened) {
      <mat-icon svgIcon="mat:keyboard_arrow_down" (click)="openSubpanel()"></mat-icon>
    } @else {
      <mat-icon svgIcon="mat:keyboard_arrow_up" (click)="closeSubpanel()"></mat-icon>
    }
  </div>
</div>

<!--<pre>-->
<!--  {{item | json}}-->
<!--</pre>-->
  <!--TODO: @dropdownAnimation-->
  @if (isSubPanelOpened) {
    <div class="flex w-full border-t-2 border-t-blue-500">
      <canvas-item-jobs class="p-5 w-full" [itemId]="item.id"></canvas-item-jobs>
    </div>
  }


